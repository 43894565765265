
<template>
    <v-container>
        <v-row class="mt-6" align="center">
            <v-col v-for="(item, index) in ListaTecnicos" :key="index" cols="12" sm="6" md="4">
                <v-card class="mx-2 rounded-lg overflow-y-auto mb-0 pb-0" height="400" min-width="200" max-width="500"
                    :href="item.url_vista_previa">
                    <v-img v-if="item.img_portada !== null" height="200" :src="item.img_portada" />
                    <v-sheet height="70" class="mx-2 mt-2 overflow-y-hidden">
                        <b class="blue--text adaptable font-weight-bold text-h6"> {{ item.titulo }}</b>
                    </v-sheet>
                    <v-card-text class="text--primary mb-0 pb-0">
                        <div class="d-flex">
                            <div class="d-flex grey--text pr-2">
                                <v-icon class="pr-1">
                                    mdi-format-list-bulleted
                                </v-icon>
                                <h3>
                                    <b>{{ item.modalidad.nombre }}</b>
                                </h3>
                            </div>
                            <div class="d-flex grey--text">
                                <v-icon class="pr-1">
                                    mdi-clock-time-five-outline
                                </v-icon>
                                <h3>
                                    <b>{{ item.duracion }}h</b>
                                </h3>
                            </div>
                        </div>
                        <div class="d-flex justify-space-between mt-3">
                            <div>
                                <h4 class="grey--text ">Inicio del curso</h4>
                                <h4 class="blue--text ">{{ moment(item.fecha_ini_curso).format('DD/MM/yyyy') }}</h4>
                            </div>
                            <div>
                                <h4 class="grey--text ">Fin de postulación</h4>
                                <h4 class="blue--text text-center">{{
                                    moment(item.fecha_fin_postulacion).format('DD/MM/yyyy') }}</h4>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-col align-self="center">
                <v-btn :href="esit_url"
                    class="mt-6 rounded-pill text-none text-md-h5 px-12 py-6" x-large color="primary">
                    <b>Ver más</b>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>

export default {
    name: "FormacionCards",
    props: {
        id_formacion: String,
        esit_url:String
    },
    data: () => ({
        ListaTecnicos: [],
    }),

    methods: {

        async getCursos() {
            const response = await this.services.formacion.getCursos({
                tipo_formacion: this.id_formacion,
                fecha_fin_postulacion: (new Date()).toISOString().slice(0,10)
            });
            this.ListaTecnicos = response?.data;
        },

    },

    async created() {
        await this.getCursos();
    },


}

</script>

<style scoped></style>