<template>
    <div v-if="version_mode === 'xl'" :class="responsive_background">
        <div class="container">

            <div style="display: flex; justify-content: space-between;">
                <div class="mod-text">
                    <div class="text-h3 text-uppercase" style="color: #223E69;"><b>¡Prepárate para el <br> mundo digital!</b></div>
                    <div class="text-h5" style="color: #223E69;">Desarrolla tu carrera profesional en la <br> ESIT y
                        conviértete en un líder de la <br> transformación digital</div>
                        <v-btn color="primary" rounded class="mt-5 text-none text-h6 px-7" large href="https://esit.gob.sv/">Visita ESIT</v-btn>
                </div>
                <div style="margin-top: 100px;">
                    <img src="../../assets/banner_image.png" width="540" height="max-content" max-width="450" max-height="250" alt="imagen de background">
                </div>
            </div>
        </div>

    </div>
    <!-- version movil -->
    <div v-else-if="version_mode === 'xs'" :class="responsive_background">
        <div class="container-movil">
            <p style="color: #FFF; font-size: 18px; text-transform: uppercase; text-align: center; margin: 10px; font-weight: 600;">¡Prepárate para el mundo digital!</p>
            <p style="color: #FFF; font-size: 15px; text-transform: initial; text-align: start; margin-left: 15px; margin-right: 25px; ">Desarrolla tu carrera profesional en la ESIT y conviértete en un líder de la transformación digital</p>
        </div>
        <div>
            <v-btn color="primary text-none" style="margin-left: 10px;" rounded href="https://esit.gob.sv/">Visita ESIT</v-btn>
        </div>
    </div>
    <div v-else-if="version_mode === 'md'" :class="responsive_background">
        <div class="container-movil">
            <p style="color: #FFF; font-size: 24px; text-transform: uppercase; text-align: center; margin: 10px; font-weight: 600;">¡Prepárate para el mundo digital!</p>
            <p style="color: #FFF; font-size: 19px; text-transform: initial; text-align: start; margin-left: 15px; margin-right: 25px; ">Desarrolla tu carrera profesional en la ESIT y conviértete en un líder de la transformación digital</p>
        </div>
        <div>
            <v-btn color="primary" style="margin-left: 10px;" rounded>Visita ESIT</v-btn>
        </div>
    </div>

</template>
<script>
export default {
    name: 'BannerFormacion',
    data: () => ({
        responsive_background: '',
        responsive_title_1: '',
        reponsive_title_2: '',
        responsive_button: '',
        responsive_img: '',
        version_mode: 'xl',
    }),
    methods: {
        handleResize() {


            if (this.$vuetify.breakpoint.xl) {
                // console.log('tipo de pant => xl');
                this.version_mode = 'xl';
                this.responsive_background = 'content-baner-xl'

            }
            if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) {
                // console.log('tipo de pant => md o lg');
                this.version_mode = 'xl';
                this.responsive_background = 'content-baner-xl'
            }

            if (this.$vuetify.breakpoint.xs) {
                // console.log('tipo de pantalla xs movil');
                this.version_mode = 'xs';
                this.responsive_background = 'custom-baner-xs'

            }

            if (this.$vuetify.breakpoint.sm) {
                // console.log('tipo de pant => sm table');
                this.version_mode = 'md';
                this.responsive_background = 'custom-baner-xs'
            }


        },

    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>
<style scoped>
.content-baner-xl {
    width: 100%;
    height: 720px;
    background: linear-gradient(315deg, #f2f2f2 20%, #BFC9D9 100%);
    border-radius: 324px 0 0 0;
}

.custom-baner-xs {
    width: 100%;
    height: 210px;
    background-color: #223E69;
    border-radius: 0 0 100px 0;
}

.container-table {
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
}
.container-movil {
    display: flex;
    flex-direction: column;
}

.mod-text {
    margin-top: 15.6rem;
    margin-left: 6.25rem;
}

</style>
