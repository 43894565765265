<template>
  <div>
    <v-container>
      <v-row class="mx-1">
        <v-col cols="12" sm="6" md="5">
          <v-btn class="blueMinsal white--text rounded-pill px-8 text-none text-md-h5" @click="$emit('mostrar', false)">
            Regresar
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="6" md="5">
          <v-text-field dense outlined rounded append-icon="mdi-magnify" v-model.lazy="searchText"
            :error-messages="textoBusqueda" />
        </v-col>
      </v-row>
      <v-row class="mb-6" justify="center">
        <v-col v-for="publicacion in publicaciones" :key="publicacion.id" cols="12" sm="6" md="3">
          <v-card class="mx-3 rounded-xl overflow-auto bg-transparent elevation-0" min-height="300" min-width="100">
            <v-img @click="verPublicacion(publicacion)" height="250"
              :src="publicacion.url" class="rounded-xl" />
            <v-card-text>
              <span class="text-title text-sm-h6 grey--text font-weight-bold font-weight-black">
                {{ publicacion.nombre }}
              </span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="!publicaciones.length" cols="auto">
          <div class="text-h5">
            {{
              searchText
              ? "Publicación no encontrada"
              : "No hay publicaciones todavía"
            }}
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-dialog v-if="dialog" v-model="dialog" transition="dialog-top-transition" width="auto" >
        <v-card>
          <v-row no-gutters justify="center">
            <v-col cols="auto" v-for="imagen in selectedPublicacion.imagenes" :key="imagen.id">
              <v-card class="elevation-0 rounded-0" height="auto">
                <v-img height="350" width="350" contain :src="imagen.url" />
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
  
<script>
import { helpers, maxLength } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/);

export default {
  name: "FormacionNoticias",

  validations: { searchText: { alpha, maxLength: maxLength(100) } },

  data: () => ({
    searchText: "",
    publicaciones: [],
    dialog: false,
    selectedPublicacion: null,
  }),

  methods: {
    async getPublicacion(filter) {
      try {
        this.publicaciones = await this.services.formacion.obtenerPublicaciones(
          {
            ...filter,
            activo: true
          }
        );
      } catch (error) {
        this.temporalAlert({
          show: true,
          message: "Error al obtener las publicaciones",
          type: "error",
        });
      }
    },

    verPublicacion(publicacion) {
      this.dialog = true;
      this.selectedPublicacion = publicacion;
    },
  },

  created() {
    this.getPublicacion();
  },

  watch: {
    searchText(newValue) {
      this.$v.$touch();
      if (!this.$v.$invalid && typeof newValue === "string") {
        this.getPublicacion({
          nombre: newValue.trim(),
        });
      }
    },
  },

  computed: {
    textoBusqueda() {
      const errors = [];
      if (!this.$v.searchText?.$dirty) return errors;
      if (!this.$v.searchText?.alpha) {
        errors.push("Contiene caracteres inválidos");
      }
      if (!this.$v.searchText?.maxLength) {
        errors.push("Máximo de caracteres 100 alcanzado");
      }
      return errors;
    },
  },
};
</script>
  
<style scoped>
h1,
span,
.v-text-field>>>fieldset,
.v-text-field>>>i {
  color: #30a5f0 !important;
}

::-webkit-scrollbar {
  display: none;
}
</style>