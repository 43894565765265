<template>
    <v-container class="transparent">
        <v-sheet
            v-if="showPhone"
            class="mx-auto px-12"
            :width="800"
            :height="520"
        >
            <v-img
                id="phone"
                src="../../assets/img/formacion/0.png"
                :width="size"
            >
                <v-row class="mx-0">
                    <v-col class="text-center">
                        <v-btn
                            id="btn-more"
                            class="primary rounded-pill px-8 text-none text-md-h5"
                            large
                            @click="$emit('mostrar', true)"
                        >
                            <b>Ver más</b>
                        </v-btn>
                    </v-col>
                    <v-spacer />
                </v-row>
            </v-img>

            <v-img
                @click="openDialog('img1')"
                ref="img1"
                src="../../assets/img/formacion/1.png"
                class="f-card rounded-xl mx-auto"
            />
            <v-img
                @click="openDialog('img2')"
                ref="img2"
                src="../../assets/img/formacion/2.png"
                class="f-card rounded-xl mx-auto"
            />
            <v-img
                @click="openDialog('img3')"
                ref="img3"
                src="../../assets/img/formacion/3.png"
                class="f-card rounded-xl mx-auto"
            />
            <v-img
                @click="openDialog('img4')"
                ref="img4"
                src="../../assets/img/formacion/4.png"
                class="f-card rounded-xl mx-auto"
            />
        </v-sheet>

        <v-row v-else>
            <v-col cols="12" sm="6">
                <v-img
                    @click="openDialog('imgs1')"
                    ref="imgs1"
                    src="../../assets/img/formacion/1.png"
                    class="f-card rounded-xl mx-auto"
                    max-width="250px"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-img
                    @click="openDialog('imgs2')"
                    ref="imgs2"
                    src="../../assets/img/formacion/2.png"
                    class="f-card rounded-xl mx-auto"
                    max-width="250px"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-img
                    @click="openDialog('imgs3')"
                    ref="imgs3"
                    src="../../assets/img/formacion/3.png"
                    class="f-card rounded-xl mx-auto"
                    max-width="250px"
                />
            </v-col>
            <v-col cols="12" sm="6">
                <v-img
                    @click="openDialog('imgs4')"
                    ref="imgs4"
                    src="../../assets/img/formacion/4.png"
                    class="f-card rounded-xl mx-auto"
                    max-width="250px"
                />
            </v-col>

            <v-col class="text-center">
                <v-btn
                    id="btn-more"
                    class="primary rounded-pill px-8 text-none text-md-h5"
                    large
                    @click="$emit('mostrar', true)"
                >
                    <b>Ver más</b>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog
            transition="dialog-top-transition"
            width="auto"
            v-model="dialog"
        >
            <v-img :src="selected" cover />
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: "PhoneCards",
    props: {
        size: { type: Number, default: 500 },
    },
    data: () => ({
        showPhone: true,
        dialog: false,
        selected: "",
    }),
    methods: {
        openDialog(ref) {
            this.selected = this.$refs[ref].src;
            this.dialog = true;
        },
    },

    mounted() {
        if (this.$vuetify.breakpoint.name.includes("s")) {
            return (this.showPhone = false);
        }

        let yAxis = -this.size;
        let xAxis = -this.size * 0.3;
        const cardSize = this.size * 0.45;
        const xOverlap = 70;
        const yOverlap = 120;
        const buttonTopPosition = this.size - 30;

        const cards = document.getElementsByClassName("f-card");
        document.getElementById(
            "btn-more"
        ).style.top = `${buttonTopPosition}px`;

        for (const key in cards) {
            if (!cards[key].style) return;
            const style = cards[key].style;

            style.width = `${cardSize}px`;
            style.height = `${cardSize}px`;

            //x position
            style.left = `${xAxis}px`;
            xAxis += cardSize - xOverlap;

            // y position
            style.top = `${yAxis}px`;

            if (key % 2 === 1) {
                style.top = `${yAxis + yOverlap}px`;
            }
            yAxis -= 220;
        }
    },

    updated() {
        if (this.$vuetify.breakpoint.name.includes("s")) this.showPhone = false;
        else this.showPhone = true;
    },
};
</script>

<style scoped>
#phone {
    z-index: 0;
}
</style>
