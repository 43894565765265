<template>
    <v-container fluid class="pa-0">
        <app-layout image="BG-Formacion.svg">
            <template #title>
                <v-card-title
                    class="subtitle-2 text-sm-h6 text-md-h5 text-lg-h3 white--text mb-n2 mb-lg-4 font-weight-black"
                >
                    Portal de formación continua
                </v-card-title>
                <v-card-text
                    class="text-h6 text-sm-h4 text-md-h3 text-lg-h1 text-center white--text font-weight-black"
                >
                    Desarrolla al máximo tus <br />habilidades profesionales
                </v-card-text>
            </template>
            <template #body-content>
                <v-main>
                    <v-container
                        fluid
                        class="mx-0 px-0 mt-16"
                        v-show="!mostrarPublicaciones && loadPhone"
                        :key="refreshPhone"
                    >
                        <PhoneCards @mostrar="switchMostrarPublicaciones" />
                        <BannerFormacion />
                    </v-container>

                    <v-container v-show="!mostrarPublicaciones">
                        <div class="text-center mb-6 mt-6">
                            <h1 class="titulo text-h2 mb-12 font-weight-black">
                                Educación Superior
                            </h1>
                            <h2
                                class="grey--text text-h3 font-weight-black mb-6"
                            >
                                Pregrado
                            </h2>
                            <h3 class="grey--text text-h4">
                                La ESIT te ofrece programas de estudio que te
                                permitirán adquirir
                                <br />
                                competencias para desempeñarte en el mundo
                                laboral digital
                            </h3>
                        </div>
                        <FormacionCards
                            id_formacion="3"
                            esit_url="https://esit.gob.sv/cursos/educacion-superior"
                        />
                    </v-container>

                    <v-container v-show="!mostrarPublicaciones">
                        <div class="text-center mb-6 mt-6">
                            <h1 class="titulo text-h2 mb-12 font-weight-black">
                                Formación Continua
                            </h1>
                            <h3 class="grey--text text-h4">
                                Adquiere nuevos conocimientos a través de
                                nuestros cursos y
                                <br />
                                certificaciones, que te permitirán desarrollar
                                al máximo tu talento digital
                            </h3>
                        </div>
                        <FormacionCards
                            id_formacion="1"
                            esit_url="https://esit.gob.sv/cursos/formacion-continua"
                        />
                    </v-container>

                    <v-container v-if="mostrarPublicaciones">
                        <NoticiasFormacion
                            @mostrar="switchMostrarPublicaciones"
                        />
                    </v-container>
                </v-main>
            </template>
        </app-layout>
    </v-container>
</template>

<script>
import AppLayout from "../../components/AppLayoutComponent.vue";
import FormacionCards from "../../components/formacion/FormacionCards";
import PhoneCards from "../../components/formacion/PhoneCards";
import NoticiasFormacion from "../../components/formacion/Noticias.vue";
import BannerFormacion from "../../components/formacion/BannerFormacion";

export default {
    components: {
        FormacionCards,
        PhoneCards,
        BannerFormacion,
        AppLayout,
        NoticiasFormacion,
    },

    data() {
        return {
            mostrarPublicaciones: false,
            loadPhone: false,
            refreshPhone: 0,
        };
    },

    mounted() {
        this.updateScrollInfo();
        window.addEventListener("scroll", this.updateScrollInfo);
        window.addEventListener("resize", this.handleResize);
    },

    beforeDestroy() {
        window.removeEventListener("scroll", this.updateScrollInfo);
        window.removeEventListener("resize", this.handleResize);
    },

    methods: {
        switchMostrarPublicaciones(payload) {
            this.mostrarPublicaciones = payload;
        },

        updateScrollInfo() {
            if (this.loadPhone) {
                window.removeEventListener("scroll", this.updateScrollInfo);
                return;
            }
            if (window.innerHeight <= 350 && window.scrollY > 200) {
                this.loadPhone = true;
                return;
            }

            if (window.innerHeight > 500 && window.scrollY > 95) {
                this.loadPhone = true;
                return;
            }

            if (
                !this.$vuetify.breakpoint.name.includes("s") &&
                window.scrollY > 95
            ) {
                this.loadPhone = true;
                return;
            }

            if (this.$vuetify.breakpoint.name.includes("s")) {
                this.loadPhone = true;
                return;
            }
        },

        handleResize() {
            this.refreshPhone++;
            if (this.refreshPhone > 99) this.refreshPhone = 0;
        },
    },
};
</script>

<style scoped>
.titulo {
    color: #223e69 !important;
}
</style>
